import React, { createContext, useContext } from "react";

const FeaturesContext = createContext();

export const FeaturesProvider = ({ children }) => {
  const features = [
    {
      title: "Competitive Battlecard Automation",
      description:
        "Stay ahead by automating competitor analysis, delivering insights when you need them most.",
    },
    {
      title: "Workflow Automation",
      description:
        "Accelerate follow-ups and POV updates, reducing delays and keeping the sales process flowing.",
    },
    {
      title: "SE Team Insights",
      description:
        "Unlock the key questions and pain points your team faces to drive targeted enablement and support.",
    },
    {
      title: "AI Customer Insights",
      description:
        "Capture real-time customer feedback and needs, ensuring your team responds with agility and precision.",
    },
    {
      title: "Real-Time Analytics & Forecasting",
      description:
        "Gain live insights and predictive trends to make data-driven decisions and stay ahead of market shifts.",
    },
  ];

  return (
    <FeaturesContext.Provider value={features}>
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeatures = () => {
  return useContext(FeaturesContext);
};
