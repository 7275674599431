// src/App.js
import React from "react";
import { ThemeProvider, CssBaseline, Container, Box } from "@mui/material";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import ProblemSection from "./components/ProblemsSection";
import SolutionSection from "./components/SolutionSection";
import FeaturesSection from "./components/FeaturesSection";
import SignupSection from "./components/SignupSection";
import Footer from "./components/Footer";
import CostSavingsChart from "./components/CostSavingsChart";
import SETimeAllocationChart from "./components/SETimeAllocationChart";
import { FeaturesProvider } from "./contexts/FeaturesProvider"; // Import the provider
import theme from "./Theme";

// console.log(`
// +++++++++++++++++++++======================================================================---------
// ++++++++++++++++++++++======================================================================--------
// ++++++++++++++++++++++++====================================================================--------
// +++++++++++++++++++++++++++=================================================================--------
// ++++++++++++++++++=:.:==++++++====================================================---========-------
// ++++++++++++++++=:::....-++++++++===============================================--++*+========------
// ++++++++++++++++:---:....:=++++++++++++======================================-:=+++***+========-----
// **+++++++++++++-:---:.....::+++++++++++++++================================::-==+*****+=========----
// ****+++++++++++:----::.....::=++++++++++++++++===========================-:---===+****============--
// ******++++++++=.--::::::....::-++++++++++++++++========================::==-::-=+++**+==============
// *************+=:---::........:-==+++++++++++++++++++=================::-=--=--=====**===============
// **************+:---::-:::::...::--++++=++++++=====================:..-=--:--====+***+===============
// **************+------::.::::::.::--=========--:::--====-===-::::...-===---=+=======+================
// ***************+:-=------:..::..:::-::++=---:-:::--:-:::-::.....:-+**+=::-------=+*+================
// ****************-:-=------:::...:::.:-:=-=--:---::-.:-:.-.::-=:+++**+*+--:----=*#*+=================
// ****************=:::--===+-:::..:..::--::---:.::::-::..:--.:-=+=+*+##*+++**+#####+==================
// ****************=:.---==-:::.......::--.:-:-:::--::---::===---=-=+=++++=#%*####%%+==================
// ****************+::--:.............:.:.::::=:::::.=:----==+=+==-++*++=+***#%%##%#+++++==============
// ****************+-::.............::::::..:::::..:.=-:-:=-=-==-==*=++=+****#######+++++++============
// *****************=::=-:..........:.:.:::::-:.:.--:-=-::--=-+-:=+==+=++++=*****##++++++++++==========
// ****************=-::::::........:....:::--:.-:::-:=+-:-+-==+====--==+++********++++++++++++=========
// **************+:...::................:::-:..-:::=-=*-:=::-*+++=:-==+==++****###*++++++++++++++======
// *************=:..................:...:--=-..-=--==-=:-=-.+#**+=---=++*+++***####*++++++++++++++=====
// ************+....:...::...........:..-=---..---==+-=-:--.+#**+=:-====++*****#####*++++++++++++++====
// ***********+-..::.....::..:---:....:.:===....:=-====:::--=##**=:--:-====+***######*+++++++++++++++==
// **********+:.............::=*+:.:=-...=+-.:.:-:-+==-:::===*##+--:-=+++******#######*+++++++++++++++=
// *****+++=-:........::......:+#=--*#++::......:.:-::-:=+**+**+++##%%%%@%#####*######*++++++++++++++++
// ++==----:::.......::........:=*#**+==-+: ......:::-=+**##**#%*%@@%%##@#++**########*++++++++++++++++
// =-----:::::......::.......:::::=++****#-. ......:-++*##**#%@@%#%@%####+-=++*########*+++++++++++++++
// -----::::::......:.......::::--------:--:......::-**###**#@@@%%%%##*+=-=++*##%%####*++++++++++++++++
// -----::::::....:..........::.::::--::.:--::....::-+**##*#%%%##*+=-----=+**##%%%%##*+++++++++++++++++
// ---::::::::...................:.:..:--:---:....::-==*####%%#*+=---==+***##%#%%%%#*+++++++++++++===++
// ---:::::::::.......::::::...........:::---::.....:++*#%####*+*+===++**########%%*+++++++++++++++=++*
// ---:::-::::::::::..::............:....:::::...::::-=*#%%#***++*+****###########*+++++++++++++++++++*
// ---:::::::::::::::::::::....   ...:::-:::.....:----+*#%##*********###%%%%#####*++++++++++++++++=+++#
// ::::::::::::::::.::..............::::..::::.::-----=+*%###**#########%%%%%%##*++++++++++++++++++++*#
// ::-::::::::::::::::::.......   ....:::::..::--==+++*#####****#%######%%%%%##*****+++++++++++++++++*%
// :-::::---::-::---::::::::...........:.......:-+=++*%%#*++**##########%%%%##***********************#@
// ::::.:::--::::----:....:::...................:==**%*====+++**##***################################%@
// ::::::::::::::::---=-:...::...................-+*+-:--=++*********##############################%%@@
// :::::::.::::------::--::.............:......::-=+-:---=*****######***###########################%@@@
// ::::::::::::--:--:-=--=-:..:...........:::::--=+*=--=++*****##########********##################%@@@
// :::::::::::::--::---==:-=-:..::.............::-=--=+++****##########*************************###%@@@
// :::::::::::::::-----=++=-=+-=:::-::::........:::::-==+**############**************************##%@@@
// :-::::::::.:::::=---==::-+++++-=-::--=--:.....:::-=++***###############***********************#%@@@@
// :::::-:-:..:::::-:---==:-+***+*#*#*==*=+++-.:----=++*+***#########%%%%%####******************##%@@@@
// ---------:::::-----=+=+++*++***#*#####****#*+*****###**#*##########%%%%%%%%#####*************##@@@@@
// -::----::::::--.:----=+=+++=++***###**#*****#**#**####**#######%#%%%%%%%%%%%%%%###***********#%@@@@@
// ------::--::...:-+=::===++=+=+***#*+**+*+***+*#*+**####*###%%#%%#%%%%%%%%%%%%#%%####********##%@@@@@
// ----::-::-::...:-::.-==.::++=*****+**#++++==*++**++**######%%%%%%%#############%%####*******#%@@@@@@
// -------:::::::.:::::-=*.:-++#**+***++#*+==-==++==+===+*##%%%%%##%######################****##%@@@@@@
// ----=---:::::::::----=*=-+-=****++*#*#**=--=====+====+##%%%%%#####********##*##*########*###%@@@@@@@
// =-----:--::::::::-:--=+.::=+--=-==+**#**+=+=++==*=+**###%%##****++*++**********##*########%%@@@@@@@@
// `);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <FeaturesProvider>
        <Header />
        <CssBaseline />
        <Container maxWidth="xl">
          <Box
            sx={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              gap: 4,
              py: 4,
            }}
          >
            <HeroSection />
            <ProblemSection />
            <SolutionSection />
            {/* <CostSavingsChart /> */}
            {/* <SETimeAllocationChart /> */}
            <FeaturesSection />
            <SignupSection />
          </Box>
        </Container>
        <Footer />
      </FeaturesProvider>
    </ThemeProvider>
  );
}

export default App;
