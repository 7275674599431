import React from "react";
import { Typography, Box, Paper, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SpeedIcon from "@mui/icons-material/Speed";
import AutomationIcon from "@mui/icons-material/AutoFixHigh";
import InsightsIcon from "@mui/icons-material/Insights";
import AiIcon from "@mui/icons-material/Psychology";
import CustomizeIcon from "@mui/icons-material/Build";
import { useFeatures } from "../contexts/FeaturesProvider";

const iconMap = {
  "Competitive Battlecard Automation": SpeedIcon,
  "Workflow Automation": AutomationIcon,
  "SE Team Insights": InsightsIcon,
  "AI Customer Insights": AiIcon,
  "Personalized Solutions": CustomizeIcon,
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "100%", // Ensures all tiles have the same width
        height: "100%", // Ensures all tiles have the same height
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ mb: 2, color: "primary.main" }}>{icon}</Box>
      <Typography variant="h6" component="h3" gutterBottom color="white">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Paper>
  );
};

function FeaturesSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const features = useFeatures();

  return (
    <Box
      sx={{
        p: { xs: 2, sm: 4 },
        borderRadius: 2,
        background:
          "linear-gradient(145deg, rgba(123, 66, 246, 0.1) 0%, rgba(0, 180, 255, 0.1) 100%)",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background icon */}
      <Box
        sx={{
          position: "absolute",
          top: -20,
          right: -20,
          fontSize: 100,
          opacity: 0.1,
          transform: "rotate(15deg)",
          color: "primary.main",
        }}
      >
        <CustomizeIcon fontSize="inherit" />
      </Box>

      {/* Title */}
      <Typography
        variant={isMobile ? "h4" : "h2"}
        gutterBottom
        align="center"
        sx={{
          mb: { xs: 3, sm: 6 },
          color: "white",
          textShadow: "0 2px 4px rgba(0,0,0,0.5)",
        }}
      >
        Features
      </Typography>

      {/* Features grid */}
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {features.map((feature, idx) => {
          const IconComponent = iconMap[feature.title] || SpeedIcon;
          return (
            <Grid
              key={idx}
              size={{ xs: 12, sm: 6, md: 4 }}
              sx={{ display: "flex" }}
            >
              <FeatureCard
                icon={<IconComponent sx={{ fontSize: 48 }} />}
                title={feature.title}
                description={feature.description}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default FeaturesSection;
