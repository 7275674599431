import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, Typography, useTheme, useMediaQuery, Paper } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CostSavingsChart = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const data = {
    labels: ["Cost", "Savings"],
    datasets: [
      {
        label: "Amount ($)",
        data: [48000, 625040],
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.secondary.main,
        ],
        borderColor: [theme.palette.primary.main, theme.palette.secondary.main],
        borderWidth: 1,
        barThickness: isMobile ? 40 : 80,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Significant Cost Savings",
        color: theme.palette.text.primary,
        font: {
          size: isMobile ? 16 : 18,
          family: theme.typography.fontFamily,
          weight: theme.typography.fontWeightBold,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
      y: {
        ticks: {
          color: theme.palette.text.secondary,
          beginAtZero: true,
        },
        grid: {
          color: theme.palette.divider,
        },
      },
    },
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: { xs: 2, sm: 3, md: 4 },
        borderRadius: 2,
        background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          height: { xs: 300, sm: 400 },
          width: "100%",
          maxWidth: 600,
          margin: "0 auto",
        }}
      >
        <Bar data={data} options={options} />
      </Box>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 2, fontSize: { xs: 12, sm: 14 } }}
      >
        Annual savings of $625,040 compared to a cost of $48,000
      </Typography>
    </Paper>
  );
};

export default CostSavingsChart;
