import React from "react";
import {
  Typography,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function SolutionSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const solutionPoints = [
    {
      main: "Optimizing Sales Performance:",
      supporting:
        "Automating follow-ups and generating real-time insights to close deals faster and improve win rates.",
    },
    {
      main: "Driving Real-Time Feedback & Innovation:",
      supporting:
        "Capturing customer feedback and market trends to guide product development and strategic adjustments.",
    },
    {
      main: "Enhancing SE Efficiency & Productivity:",
      supporting:
        "Reducing manual tasks, freeing SEs to focus on high-impact work that drives growth and satisfaction.",
    },
    // {
    //   main: "Enhancing Customer Understanding:",
    //   supporting:
    //     "Leveraging data-driven insights to deepen customer needs analysis, aligning solutions with real-world requirements.",
    // },
    // {
    //   main: "Strengthening Product Knowledge:",
    //   supporting:
    //     "Enabling Sales Engineers with continuous learning and quick access to product updates, ensuring they’re always prepared to deliver value.",
    // },
  ];

  return (
    <Box
      sx={{
        p: { xs: 3, sm: 5 },
        borderRadius: 2,
        background:
          "linear-gradient(145deg, rgba(123, 66, 246, 0.1) 0%, rgba(0, 180, 255, 0.1) 100%)",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -20,
          right: -20,
          fontSize: 100,
          opacity: 0.1,
          transform: "rotate(15deg)",
          color: "primary.main",
        }}
      >
        <AutoAwesomeIcon fontSize="inherit" />
      </Box>
      <Typography
        variant={isMobile ? "h4" : "h2"}
        gutterBottom
        align="center"
        sx={{
          color: theme.palette.text.primary,
          textShadow: "0 2px 4px rgba(0,0,0,0.5)",
          mb: { xs: 3, sm: 6 },
          // fontWeight: 600,
        }}
      >
        Introducing raggity.ai
      </Typography>
      <Paper
        elevation={3}
        sx={{
          p: { xs: 3, sm: 5 },
          maxWidth: "800px",
          mx: "auto",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{
            // fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
            color: "rgba(255, 255, 255, 0.7)",
            mb: 3,
            lineHeight: 1.4,
          }}
        >
          At{" "}
          <Typography
            component="span"
            variant="inherit"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            raggity.ai
          </Typography>
          , we empower Sales Engineers to improve the pulse of their
          organization by:
        </Typography>
        <List sx={{ mt: 2 }}>
          {solutionPoints.map((point, index) => (
            <ListItem key={index} sx={{ py: 1, px: 0 }}>
              <ListItemIcon>
                <CheckCircleOutlineIcon
                  sx={{ color: theme.palette.primary.main, fontSize: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant={isMobile ? "body1" : "h6"}
                      sx={{
                        fontWeight: 600,
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      {point.main}
                    </Typography>{" "}
                    <Typography
                      component="span"
                      variant={isMobile ? "body2" : "body1"}
                      sx={{
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {point.supporting}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

export default SolutionSection;
