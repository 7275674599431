import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Slide,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFeatures } from "../contexts/FeaturesProvider";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
const AWS = require("aws-sdk");
const dynamoDB = new AWS.DynamoDB.DocumentClient();

function SignupSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const features = useFeatures();
  const [showPanel, setShowPanel] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    selectedFeatures: [],
    notes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (feature) => {
    setFormData((prevData) => {
      const selectedFeatures = prevData.selectedFeatures.includes(feature)
        ? prevData.selectedFeatures.filter((f) => f !== feature)
        : [...prevData.selectedFeatures, feature];
      return { ...prevData, selectedFeatures };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = {
      name: formData.name,
      company: formData.company,
      email: formData.email,
      selectedFeatures: formData.selectedFeatures,
      notes: formData.notes,
    };

    try {
      const response = await fetch(
        "https://48zt3etndb.execute-api.us-east-1.amazonaws.com/Prod/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      const result = await response.json();
      console.log(result);

      // Handle successful signup (e.g., show a success message, clear form, etc.)
      alert("Signup successful!");
      setFormData({
        name: "",
        company: "",
        email: "",
        selectedFeatures: [],
        notes: "",
      });
      setShowPanel(false);
    } catch (error) {
      console.error("Error:", error);
      if (
        error instanceof TypeError &&
        error.message.includes("Failed to fetch")
      ) {
        alert(
          "Unable to connect to the server. This might be due to a CORS issue. Please contact support."
        );
      } else {
        alert("Error submitting signup. Please try again.");
      }
    }
  };

  const GradientTypography = styled(Typography)(({ theme }) => ({
    background: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }));

  return (
    <Box
      sx={{
        p: { xs: 2, sm: 4 },
        borderRadius: 2,
        background:
          "linear-gradient(180deg, rgba(123, 66, 246, 0.1) 0%, rgba(0, 180, 255, 0.1) 100%)",
        position: "relative",
        overflow: "hidden",
      }}
      id="signup"
    >
      <GradientTypography
        variant={isMobile ? "h3" : "h2"}
        gutterBottom
        align="center"
        sx={{
          color: "white", // Ensure consistency with other section headers
          textShadow: "0 2px 4px rgba(0,0,0,0.5)",
          mb: { xs: 3, sm: 6 },
        }}
      >
        Sign Up for Early Access
      </GradientTypography>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => setShowPanel(!showPanel)}
          sx={{
            py: 1.5,
            px: 4,
            borderRadius: 50,
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        >
          {showPanel ? "Hide Form" : "Sign Up for Early Access"}
        </Button>
      </Box>
      <Slide direction="up" in={showPanel} mountOnEnter unmountOnExit>
        <Paper
          elevation={6}
          sx={{
            p: 4,
            bgcolor: "background.default",
            borderRadius: 2,
            maxWidth: "600px", // Restrict width for compact design
            mx: "auto", // Center the form horizontally
            position: "relative",
          }}
        >
          <IconButton
            onClick={() => setShowPanel(false)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <TextField
              label="Company"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              required
            />
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <Typography variant="h6" gutterBottom>
              Select Features (at least one required)
            </Typography>
            <Box sx={{ mb: 2 }}>
              {features.map((feature, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={formData.selectedFeatures.includes(
                        feature.title
                      )}
                      onChange={() => handleCheckboxChange(feature.title)}
                    />
                  }
                  label={feature.title}
                />
              ))}
            </Box>
            <TextField
              label="Additional Notes"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              sx={{ mb: 2 }}
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              sx={{
                mt: 2,
                py: 1.5,
                borderRadius: 50,
              }}
            >
              Sign Up
            </Button>
          </Box>
        </Paper>
      </Slide>
    </Box>
  );
}

export default SignupSection;
